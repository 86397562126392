<template>
  <div>
    <form @submit.prevent="onSubmit">
      <vx-card title="Form Add">

        <div class="vx-row mb-6">

          <div class="vx-col sm:w-3/12 w-full">
            <label class="vs-input--label">{{$i18n.t('PODate')}}</label>
            <datepicker format="d/MM/yyyy" :value="form.tanggal" @input="form.tanggal = $moment($event)"></datepicker>
          </div>

          <div class="vx-col sm:w-4/12 w-full">
            <vs-input
              class="w-full"
              type="text"
              :label="$i18n.t('PONumber')"
              v-model="form.nomor"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              :danger="error.nomor ? true : false"
              :danger-text="error.nomor ? error.nomor.join(', ') : ''"
            />
          </div>

          <div class="vx-col sm:w-5/12 w-full">
            <vs-input
              class="w-full"
              type="text"
              :label="$i18n.t('ContractNumber')"
              v-model="form.no_kontrak"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              :danger="error.no_kontrak ? true : false"
              :danger-text="error.no_kontrak ? error.no_kontrak.join(', ') : ''"
            />
          </div>

        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input
              class="w-full"
              type="text"
              :label="$i18n.t('CustomerName')"
              v-model="form.nama_customer"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              :danger="error.nama_customer ? true : false"
              :danger-text="error.nama_customer ? error.nama_customer.join(', ') : ''"
            />
          </div>
          <div class="vx-col sm:w-8/12 w-full">
            <vs-input
              class="w-full"
              type="text"
              :label="$i18n.t('ProjectName')"
              v-model="form.nama_proyek"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              :danger="error.nama_proyek ? true : false"
              :danger-text="error.nama_proyek ? error.nama_proyek.join(', ') : ''"
            />
          </div>
        </div>

        <div class="mb-6">

          <vx-card :title="$i18n.t('Spesification')" style="border: 1px solid #ccc;">

            <template slot="actions">
              <vs-button style="margin: 0 auto;" color="primary" size="small" icon-pack="feather" icon="icon-plus" class="mr-4" @click="() => { popupSpesifications = true }">
                {{ $i18n.t('AddSpesification') }}
              </vs-button>
            </template>

            <vs-table :data="form.specifications">

              <template slot="thead">
                <vs-th>{{$i18n.t('Name')}}</vs-th>
                <vs-th class="text-center">{{$i18n.t('Qty')}}</vs-th>
                <vs-th class="text-right">{{$i18n.t('Price')}}</vs-th>
                <vs-th class="text-right">{{$i18n.t('Total')}}</vs-th>
                <vs-th class="text-center">Action</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr v-for="(tr, i) in data" :key="tr.id">

                  <vs-td :data="tr.name">
                    {{ tr.name }}
                  </vs-td>

                  <vs-td :data="tr.qty">
                    {{ tr.qty }}
                  </vs-td>

                  <vs-td :data="tr.price">
                    {{ currencies[tr.currency-1].text }} {{ formatNumber(tr.price) }}
                  </vs-td>

                  <vs-td :data="tr.total">
                    {{ currencies[tr.currency-1].text }} {{ formatNumber(tr.price * tr.qty) }}
                  </vs-td>

                  <vs-td>
                    <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="form.specifications.splice(i, 1)" />
                    <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr hover:text-danger cursor-pointer" @click="editSpesification(tr)" />
                  </vs-td>

                </vs-tr>
              </template>

            </vs-table>

            <vs-popup classContent="popup-example" :title="$i18n.t('Spesification')" :active.sync="popupSpesifications">
              <div class="vx-row mb-6">
                <div class="vx-col md:w-1/1 w-full">
                   <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="handle.specification.name" />
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col md:w-1/3 w-full">
                  <vs-input-number min="1" v-model="handle.specification.qty" label="qty:"/>
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col md:w-1/4 w-full">
                  <small class="mb-1">{{ $i18n.t('Currency') }}</small>
                  <v-select :clearable="false" v-model="handle.specification.currency" :options="currencies" :reduce="item => item.value" label="text"></v-select>
                </div>
                <div class="vx-col md:w-3/4 w-full">
                  <vs-input type="number" class="w-full mb-3" :label="$i18n.t('Price')" v-model="handle.specification.price" />
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col md:w-1/1 w-full">
                  <vs-input type="number" class="w-full mb-3" label="Total" :value="handle.specification.price * handle.specification.qty" readonly=""/>
                </div>
              </div>
              <vs-button color="primary" type="filled" @click="addSpecification" v-if="!isSelected">Add</vs-button>
              <vs-button color="primary" type="filled" @click="updateSpecification" v-else>Update</vs-button>
            </vs-popup>

          </vx-card>

        </div>

        <div class="vx-row mb-6">
          <div class="vx-col md:w-1/4 w-full">
            <small class="mb-1">{{ $i18n.t('Currency') }}</small>
            <v-select :clearable="false" v-model="form.currency_diskon" :reduce="item => item.value" :options="currencies" label="text"></v-select>
          </div>

          <div class="vx-col md:w-3/4 w-full">
            <vs-input :label="$i18n.t('Discount')" name="diskon" v-model="form.diskon" class="w-full" />
            <span class="text-danger">{{ errors.first('step-2.diskon') }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col md:w-1/2 w-full">
            <vs-input :label="$i18n.t('DONumber')" name="delivery_order" v-model="form.delivery_order" class="w-full" />
            <span class="text-danger">{{ errors.first('step-2.delivery_order') }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <vs-input :label="$i18n.t('SN')" name="sn" v-model="form.sn" class="w-full" />
            <span class="text-danger">{{ errors.first('step-2.sn') }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full">
            <div class="flex">
              <vs-button class="mr-3 mb-2" icon-pack="feather" icon="icon-save" @click.prevent="onSubmit">{{ $i18n.t('Save') }}</vs-button>
              <vs-button type="flat" color="primary" class="mb-2" @click.prevent="$router.push({ name: 'purchase' })">{{ $i18n.t('Cancel') }}</vs-button>
            </div>
          </div>
        </div>

      </vx-card>
    </form>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker'

export default {
  name: "QuotationForm",
  data: () => ({
    isSelected: false,
    popupSpesifications: false,
    form: {
      specifications: [
      ],
    },
    handle: {
      specification: {
        name: '',
        qty: 1,
        price: 0,
        currency: ''
      }
    },
    currencies: [
      {
        text: 'USD',
        value: '1'
      },
      {
        text: 'EUR',
        value: '2'
      },
      {
        text: 'IDR',
        value: '3'
      }
    ],
    error: {}
  }),
  methods: {
    addSpecification () {
      this.handle.specification.total = this.handle.specification.qty * this.handle.specification.price;
      this.form.specifications.unshift(this.handle.specification);

      this.handle.specification = {
        name: '',
        qty: 1,
        price: 0,
        currency: 1
      };

      this.popupSpesifications = false;
      this.isSelected = false;
    },
    updateSpecification () {
      this.popupSpesifications = false;
    },
    editSpesification (item) {
      this.isSelected = item;
      this.handle.specification = item;
      this.handle.specification.currency = this.currencies.find(currency => currency.value == this.handle.specification.currency).value;
      console.log(this.handle.specification.currency)
      this.popupSpesifications = true;
    },
    onSubmit () {
      let type = 'purchase/storeItem', payload = { body: this.form };
      if (this.id) {
        type = 'purchase/updateItem';
        payload.body._method = 'PUT';
        payload.id = this.id;
      }

      this.$store.dispatch(type, payload).then((response) => {
        console.log(payload)
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          if (payload.id) {
            this.$router.push({name: 'purchase-show', params: { id: this.$route.params.id }});
          } else {
            this.$router.push({name: 'purchase'});
          }
        });
      }).catch(err => {

        // set error
        if (err.data.errors) {
          this.error = err.data.errors;
        }

        // show message error
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })

      });
    },
    total (data) {
      let currencies = this.currencies
        .map(currency => {
          currency.total = data.filter(item => item.currency == currency.value).map(item => item.total);
          return currency;
        })
        .filter(currency => currency.total.length > 0)
        .map(currency => {
          currency.total = currency.total.reduce((total, item) => total += item);
          return `${currency.text} ${this.formatNumber(currency.total)}`;
        });

      return currencies.join(' + ');
    },
    formatNumber (value) {
      let val = (value/1).toFixed(2).replace('.', ',');
      let result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      return result;
    }
  },
  computed: {
    ...mapGetters({
      'item': 'purchase/showItem'
    })
  },
  components: {
    Datepicker,
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$secure.decrypt(this.$route.params.id);
      this.$store.dispatch('purchase/detailItem', { id: this.id }).then(() => {
        this.form = this.item;
        this.form.currency_diskon = this.currencies.find(currency => currency.value == this.form.currency_diskon);

        console.log(this.form);
      });
    }
  }
}
</script>

<style scoped>

</style>
